import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Camera from "../images/404-camera@2x.jpg";

const NotFoundPageStyles = styled.div`
  .container {
    padding: 4rem 3rem 2rem;

    h1 {
      margin: 3rem 0;
    }

    p {
      margin-bottom: 3rem;
    }
  }

  @media screen and (min-width: 981px) {
    background-size: cover;
    background-position: left 30vw top 100%;
    background-repeat: no-repeat;

    .container {
      height: calc(100vh - 132px);
      width: calc(100% - 6rem);
      max-width: 1645px;
      margin: 0 auto;

      .row {
        align-items: center;
        height: 100%;

        .col {
          flex-basis: 50%;
        }
      }
    }
  }
`;

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />

    <NotFoundPageStyles style={{ backgroundImage: `url(${Camera})` }}>
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="sub-title">Lights, Cameras... Action?</div>
            <h1>Ah, shoot. Looks like this page went off-script.</h1>
            <p>Hang tight, we'll get you back on track.</p>
            <Link to="/" className="button">
              home
            </Link>
          </div>
        </div>
      </div>
    </NotFoundPageStyles>
  </Layout>
);

export default NotFoundPage;
